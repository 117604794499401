import { BrowserRouter, Routes, Route, Link, Navigate, useNavigate } from 'react-router-dom';
import React, { useState, createContext, useContext } from 'react';
import {useMediaQuery} from 'react-responsive';
import projectsdat from './projects_data.json'
import ProjectInfo from './project';
import './App.css';
import AboutLink from './components/AboutLink';
import AboutPlainText from './components/AboutPlainText';

const Mobile = ({children}) => {
  const isMobile = useMediaQuery({
    query : "(max-width:768px)"
  });
  
  return <>{isMobile && children}</>
}

const PC = ({children}) => {
  const isPc = useMediaQuery({
    query : "(min-width:769px)"
  });
  
  return <>{isPc && children}</>
}

let projects = projectsdat.projectsdat;

let StateContext = createContext({
  stat:'home', 
  setStat:()=>{}
});

function ProjectWrapper(props){
  const titleStyle = {
    fontSize : 'medium',
    fontWeight: 'normal',
    paddingLeft : '10px'
  }
  const navigate = useNavigate();

  let value = useContext(StateContext)

  const [sortState, setSort] = useState('year');


  const goToProject = (i) => {
    let project = projects[i];
    value.setStat(i);
    navigate(project.url);
  };

  const sortYear = () => {
    projects.sort(function(a, b){
      return Number(a.year) > Number(b.year) ? -1 : Number(a.year) < Number(b.year) ? 1 : 0;
    })
  }
  const sortScope = () => {
    projects.sort(function(a, b){
      return a.scope > b.scope ? -1 : a.scope < b.scope ? 1 : 0;
    })
  }
  const sortName = () => {
    projects.sort(function(a, b){
      return a.title > b.title ? -1 : a.title < b.title ? 1 : 0;
    })
  }
  
  switch(sortState){
    case'scope' :
      sortScope();
      break;
    case 'name' :
      sortName();
      break;
    default :
      sortYear();
      break;
  }
  

  if(props.mobile === 'true'){
    return(
      <section className='project-wrapper'>
      <h1 style={titleStyle}>{props.projectTitle}</h1>
      <table className='project-table'>
      <th align='start' onClick={()=>{setSort('name')}}>NAME</th>
      <th align='start' onClick={()=>{setSort('year')}}>YEAR</th>

      {
        projects.map(function(project, index){
          if(project.status === 'wip'){
            return(
            <tr className='project-single-element no-grab' onClick={()=>{alert('This project is in progress!')}}>
              <td>{project.title} <i>&#40;work in progress&#41;</i></td>
              <td>{project.year}</td>
            </tr>
          )
          }else{
            return(
            <tr className='project-single-element' onClick={()=>{goToProject(index)}}>
              <td>{project.title}</td>
              <td>{project.year}</td>
            </tr>
          )
          }
          })
      }
      </table>
    </section>
    )
  }else{
    return(
      <section className='project-wrapper'>
        <h1 style={titleStyle}>{props.projectTitle}</h1>
        <table className='project-table'>
        <th align='start' onClick={()=>{setSort('name')}}>NAME</th>
        <th align='start' onClick={()=>{setSort('scope')}}>SCOPE</th>
        <th align='start' onClick={()=>{setSort('year')}}>YEAR</th>
        {
          projects.map(function(project, index){
            if(project.status === 'wip'){
              return(
              <tr className='project-single-element no-grab' onClick={()=>{alert('This project is in progress!')}}>
                <td>{project.title} <i>&#40;work in progress&#41;</i></td>
                <td>{project.scope}</td>
                <td>{project.year}</td>
              </tr>
            )
            }else{
              return(
              <tr className='project-single-element' onClick={()=>{goToProject(index)}}>
                <td>{project.title}</td>
                <td>{project.scope}</td>
                <td>{project.year}</td>
              </tr>
            )
            }
            })
        }
        </table>
      </section>
    )
  }

  
}

function LeftSection(props){
  let className = 'section-style';

  if(props.mobile === 'true'){
    className = 'section-style-mo'
  }else{
    className = 'section-style'
  }

  let leftStyle = {
    borderRight : 'solid 1px black'
  }

  let value = useContext(StateContext);

  let [projectState, setProject] = useState('none');

  return(
    <section className={className} style={leftStyle}>
      <header className='header'><Link to={'/'} className='header-button' onClick={()=>{value.setStat('home')}}>SHINN JIWOONG / 신지웅</Link></header>
      <ProjectWrapper mobile = {props.mobile} projectTitle='(WORKS)' projectSetter = {setProject}/>
      <footer className='footer'><a href='mailto:shinnjiwoong@gmail.com'>SHINNJIWOONG@GMAIL.COM</a></footer>
    </section>
  )
}

function RightSection(props){
  let introduction_en = 'Jiwoong Shinn is a developer and a music producer based in Seoul, Korea. Current member of 926 Studio and .WAV.'
  let introduction_kr = '신지웅은 서울에서 개발자 및 음악 프로듀서로 활동중이다. 현재 926 스튜디오와 .WAV의 멤버로 활동중이다.'
  let school = 'Seoul National University of Science & Technology, 2023'

  let className = 'section-style';

  let value = useContext(StateContext)

  if(props.mobile === 'true'){
    className = 'section-style-mo'
  }else{
    className = 'section-style'
  }

  let imageStyle = {
    width : '450px',
    height : 'auto',
    marginTop : '20px'
  }

  if(value.stat === 'about'){

    return(
      <section className={className}>
        <header className='header'><Link to={'/About'} className='header-button' onClick={()=>{value.setStat('about')}}>&#40;ABOUT&#41;</Link></header>
        <section className='about-wrapper about-text-style'>
          <AboutPlainText text={introduction_en}/>
          <br/>
          <AboutPlainText text={introduction_kr}/>
          <br/>
          <AboutPlainText text={'EDUCATION'}/>
          <AboutPlainText text={school}/>
          <br/>
          <AboutPlainText text={'CONTACT'}/>
          <AboutLink href = {'mailto:shinnjiwoong@gmail.com'} text = {'E. SHINNJIWOONG@GMAIL.COM'}/>
          <AboutLink href = {'https://www.instagram.com/shinnjiwoong/'} text = {'IG. @SHINNJIWOONG'}/>
          <AboutLink href = {'https://www.instagram.com/mt.kyt/'} text = {'IG. @MT.KYT'}/>
        </section>
      </section>
    )
  }else if(value.stat === 'home'){
    return(
      <section className={className}>
        <header className='header'><Link to={'/About'} className='header-button' onClick={()=>{value.setStat('about')}}>&#40;ABOUT&#41;</Link></header>
        <div className='media-wrapper'>
          <img className="logo-img" style={imageStyle} alt="" src="web_img/신지웅로고.png" />
        </div>
      
      </section>
  )
  }else{
    return(
      <section className={className}>
        <header className='header'><Link to={'/About'} className='header-button' onClick={()=>{value.setStat('about')}}>&#40;ABOUT&#41;</Link></header>
        <ProjectInfo projectIndex = {value.stat}/>
      </section>
  )
  }

}

function MobileSection(props){
  let introduction_en = 'Jiwoong Shinn is a developer and a music producer based in Seoul, Korea. Current member of 926 Studio and .WAV.'
  let introduction_kr = '신지웅은 서울에서 개발자 및 음악 프로듀서로 활동중이다. 현재 926 스튜디오와 .WAV의 멤버로 활동중이다.'
  let school = 'Seoul National University of Science & Technology, 2023'

  let className = 'section-style-mo'
  let value = useContext(StateContext)

  let [projectState, setProject] = useState('none');

  let imageStyle = {
    width : '250px',
    height : 'auto',
    marginTop : '20px'
  }

  if(value.stat === 'home'){
    return(
      <section className={className}>
        <header className='header'><Link to={'/'} className='header-button' onClick={()=>{value.setStat('home')}}>SHINN JIWOONG / 신지웅</Link><Link to={'/About'} className='header-button' onClick={()=>{value.setStat('about')}}>&#40;ABOUT&#41;</Link></header>
        <ProjectWrapper mobile = {props.mobile} projectTitle='(Works)' projectSetter = {setProject}/>
      </section>
  )
  }else if(value.stat === 'about'){
    return(
      <section className={className}>
        <header className='header'><Link to={'/'} className='header-button' onClick={()=>{value.setStat('home')}}>SHINN JIWOONG / 신지웅</Link><Link to={'/About'} className='header-button' onClick={()=>{value.setStat('about')}}>&#40;ABOUT&#41;</Link></header>
        <section className='about-wrapper about-text-style'>
          <AboutPlainText text={introduction_en}/>
          <br/>
          <AboutPlainText text={introduction_kr}/>
          <br/>
          <AboutPlainText text={'EDUCATION'}/>
          <AboutPlainText text={school}/>
          <br/>
          <AboutPlainText text={'CONTACT'}/>
          <AboutLink href = {'mailto:shinnjiwoong@gmail.com'} text = {'E. SHINNJIWOONG@GMAIL.COM'}/>
          <AboutLink href = {'https://www.instagram.com/shinnjiwoong/'} text = {'IG. @SHINNJIWOONG'}/>
          <AboutLink href = {'https://www.instagram.com/mt.kyt/'} text = {'IG. @MT.KYT'}/>
          <div className='media-wrapper'>
            <img className="logo-img" style={imageStyle} alt="" src="web_img/신지웅로고.png" />
          </div>
        </section>
      </section>
  )
  }else{
    return(
      <section className={className}>
        <header className='header'><Link to={'/'} className='header-button' onClick={()=>{value.setStat('home')}}>&#40;WORKS&#41;</Link><Link to={'/About'} className='header-button' onClick={()=>{value.setStat('about')}}>&#40;ABOUT&#41;</Link></header>
        <ProjectInfo mobile = {props.mobile} projectIndex = {value.stat}/>
      </section>
    )
  }
  
}


function App() {
  let [stat, setStat] = useState('home')

  return (
    <StateContext.Provider value={{stat:stat, setStat:setStat}}>
      <BrowserRouter>
        <PC>
          <div className="App">
            <main className='main-section'>
              <LeftSection/>
              <RightSection/>
            </main>
          </div>
        </PC>
        <Mobile>
          <main className='main-section-mo'>
            <MobileSection mobile = 'true' />
          </main>
        </Mobile>
      </BrowserRouter>
    </StateContext.Provider>
  );
}

export default App;
